






































































































































import {
  SfAccordion, SfIcon, SfImage, SfLink, SfList, SfMenuItem, SfSidebar
} from '~/components';
import {
  defineComponent, useRouter, useContext, computed, onMounted, onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { useUiState, useUser, useWebsiteCode } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useMobileCategoryTree } from './logic';
import transparentPlaceholder from '~/styles/images/transparent_placeholder.svg';
import { useConfigStore } from "~/stores/config";
import { useTopBar } from '~/components/TopBar/useTopBar';

export default defineComponent({
  components: {
    SfLink,
    SfAccordion,
    SfSidebar,
    SfList,
    SfMenuItem,
    SfIcon,
    SfImage,
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
  },
  props: {
    categoryTree: {
      default: () => [],
    },
  },
  setup(props) {
    const router = useRouter();
    const app = useContext();
    const {
      toggleDesktopMenu,
      toggleMobileMenu,
    } = useUiState();

    const { isMe, isAu } = useWebsiteCode();
    const storePhone = computed(() => useConfigStore().storeConfig.store_information?.phone);
    const { isAuthenticated, user } = useUser();
    const {hasCurrencyToSelect, hasStoresToSelect} = useTopBar();

    const categoryTree = computed(() => props.categoryTree);

    const { categoryAncestors: initialHistory } = useTraverseCategory();

    const navigate = (category: CategoryTree) => {
      toggleMobileMenu();
      router.push(decodeURIComponent((app.localePath(category.url))));
    };

    // A category with no child categories can't be entered into - it can only navigated to
    const initialHistoryWithSnippedSubcategoryLessTail = initialHistory.value.at(-1)?.children?.length
      ? initialHistory.value
      : initialHistory.value.slice(0, -1);

    const {
      current: currentCategory, history, currentItems, onGoCategoryUp, onGoCategoryDown,
    } = useMobileCategoryTree();

    const itemsIncludedInMenu = computed(() => {
      const topLevelItems = categoryTree.value;
      return currentItems.value || topLevelItems;
    });

    const navigationMediaQuery = window.matchMedia('(min-width: 1024px)');
    const navigationSwitcher = (event) => {
      if (event.matches) {
        toggleDesktopMenu();
        toggleMobileMenu();
      }
    }

    const userFirstName = computed(() => user.value.firstname);

    onMounted(() => {
      navigationMediaQuery.addEventListener('change', navigationSwitcher);
    })

    onBeforeUnmount(() => {
      navigationMediaQuery.removeEventListener('change', navigationSwitcher);
    })

    return {
      currentCategory,
      onGoCategoryUp,
      onGoCategoryDown,
      history,
      navigate,
      toggleMobileMenu,
      itemsIncludedInMenu,
      isAuthenticated,
      transparentPlaceholder,
      isMe,
      isAu,
      storePhone,
      hasCurrencyToSelect,
      userFirstName,
    };
  },
});
