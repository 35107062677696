import { computed, ref } from '@nuxtjs/composition-api';
import { CategoryTree } from '~/modules/GraphQL/types';

export const useMobileCategoryTree = (initialHistory: CategoryTree[] = []) => {
  const history = ref<CategoryTree[]>(initialHistory);
  const current = computed<CategoryTree | null>(() => history.value.at(-1) ?? null);


  const currentItems = computed<CategoryTree[]>(() => {
    const data: CategoryTree[] = [];

    if (current.value && current.value.children) {
      current.value.children.forEach((categoryItem: CategoryTree) => {
        if (categoryItem.css_classes?.includes('hidden-title')) {
          categoryItem.children.forEach((subCategoryItem) => {
            data.push(subCategoryItem);
          })
        } else {
          data.push(categoryItem);
        }
      })
    } else {
      return null;
    }

    return data;
  });


  const onGoCategoryDown = (category: CategoryTree) => {
    history.value.push(category);
  };
  const onGoCategoryUp = () => history.value.pop();

  return {
    history,
    current,
    currentItems,
    onGoCategoryUp,
    onGoCategoryDown,
  };
};
